import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './LandingPageMembers.css';
import LandingPageMemberCard from '../LandingPageMemberCard/LandingPageMemberCard'
// import mb from '../../assests/MemeberImages/mb.png'
// import mg from '../../assests/MemeberImages/mg.png'
// import as from '../../assests/MemeberImages/as.png'
// import rr from '../../assests/MemeberImages/rr.png'
import { db } from '../../firebaseConfig'
import PrimaryMemberCard from '../PrimaryMemberCard/PrimaryMemberCard';


function LandingPageMembers(props) {
  const [memberList, setmemberList] = useState([])
  const [membersLoaded, setmembersLoaded] = useState(false)
  const [coreList, setcoreList] = useState([])

  useEffect(() => {
    if (!membersLoaded) {
      const memList = []
      const cores = []
      db.collection("members").where('onMainPage', '==', true).orderBy('index', 'asc').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var docData = doc.data()
          if (!docData['isCore'] && !props.forCore) {
            memList.push({
              'name': docData['name'],
              'gender': docData['gender'],
              'imgUrl': docData['imgUrl'],
              'post': docData['post']
            })
          } else if (docData['isCore']) 
            {
            cores.push({
              'name': docData['name'],
              'gender': docData['gender'],
              'imgUrl': docData['imgUrl'],
              'post': docData['post']
            })
          }
        });
      }).then(() => { setmemberList(memList); setmembersLoaded(true); setcoreList(cores) });
    }

  }, [])

  return (
    <div className="LandingPageMembers">
      <div className={props.forCore?'team':'MemberList'}>
        {
          props.forCore ? coreList.map((mem) => { return <PrimaryMemberCard imgUrl={mem.imgUrl} name={mem.name} gender={mem.gender} post={mem.post} /> }):memberList.map((mem) => { return <PrimaryMemberCard imgUrl={mem.imgUrl} name={mem.name} gender={mem.gender} post={mem.post} /> }) 
        }
      </div>
    </div>
  );
}

LandingPageMembers.propTypes = {};

LandingPageMembers.defaultProps = {};

export default LandingPageMembers;
