const transData = {
    "English":{
        "home":"Home",
        "about":"About",
        "donate":"Countribution",
        "members":"Members",
        "events":"Events",
        "join now":"Join Now",
        "our leaders":"Our Leaders",
        "core members":"Core Members"
    },
    "Hindi":{
        "home":"होम",
        "about":"आमच्याबद्दल",
        "donate":"योगदान",
        "members":"सदस्य",
        "events":"कार्यक्रम",
        "join now":"नाव नोंदणी",
        "our leaders":"Our Leaders",
        "core members":"कोर कमिटी"
    }
}

export default function getTranslation(lang,key){
    return transData[lang][key];
}