import React from 'react';
import PropTypes from 'prop-types';
import './EventPage.css';
import EventHolder from '../EventHolder/EventHolder';

const EventPage = () => (
  <div className="EventPage">
    <div className='memberTitleDiv'>
      {/* <div className='memberOverlay'></div> */}
      <div className='TitleHolder'>
        <div className='MemberTitle'>Events</div>

      </div>
    </div>
        <EventHolder />
  </div>
);

EventPage.propTypes = {};

EventPage.defaultProps = {};

export default EventPage;
