import React from 'react';
import PropTypes from 'prop-types';
import './HomePage.css';
import PartyName from '../PartyName/PartyName';
import VisionContainer from '../VisionContainer/VisionContainer'
import LandingPageMembers from '../LandingPageMembers/LandingPageMembers'
import { useHistory } from 'react-router-dom';
import getTranslation from '../../utils/translationHelper';
import EventHolder from '../EventHolder/EventHolder';
import PrimaryMemberCard from '../PrimaryMemberCard/PrimaryMemberCard';

function HomePage(props) {
  const history = useHistory()
  return (
    <div className="HomePage">
      <div className='Bg_Img'>
        <div className='nameHolder'>
          <div className='name_and_button'>
            <PartyName />
            <button onClick={()=>{history.push("/join")}}>{getTranslation(props.language,"join now")}</button>
          </div>
          <div></div>
        </div>
      </div>
      <div className='ourVision'>
        <div className='ourVision-title'>Our Vision</div>
        <div className='visions'>
          <VisionContainer bgCol='rgba(195, 136, 98, 0.8)' info='Basic education facility available to all. Skill development in all the field. Entrepreneurship skill development' title='Education in wide sense' bgImg='https://images.unsplash.com/photo-1524069290683-0457abfe42c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80' />
          <VisionContainer bgCol='rgba(2, 117, 171, 0.8)' info='Job / Services oriented openings. Small business set-up. MSME -Micro Scale Medium Enterprise' title='Employment in diverse Sectors' bgImg='https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80' />
          <VisionContainer bgCol='rgba(19, 51, 25, 0.8)' info='Empower poor in health, wealth, thoughts. Empower women, down-trodden. Empower weaker section of society' title='Empowerment' bgImg='https://images.unsplash.com/photo-1592530392525-9d8469678dac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80' />
          <VisionContainer bgCol='rgba(168, 89, 63, 0.8)' info='Task to improve environment; air, water, noise pollution. Health of people, plants & animals. Corrective counter measures to protect Earth environment.' title='Environment & Health' bgImg='https://images.unsplash.com/photo-1466611653911-95081537e5b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80' />
        </div>
      </div>
      <div className='ourVision'>
        <div className='ourVision-title'>Our Leaders</div>
        <LandingPageMembers forCore={false} />
      </div>
      <div className='ourVision'>
        <div className='ourVision-title'>{getTranslation(props.language,"core members")}</div>
        <LandingPageMembers forCore={true}/>
      </div>
      <div className='ourVision'>
        <div className='ourVision-title'>Events</div>
        <EventHolder main_page={true}/>
      </div>
    </div>
  );
}

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
