import React from 'react';
import PropTypes from 'prop-types';
import './LoadingPage.css';

const LoadingPage = () => (
  <div className="LoadingPage">
    <div class="load">
    <div class="load-one"></div>
    <div class="load-two"></div>
    <div class="load-three"></div>
  </div>
  </div>
);

LoadingPage.propTypes = {};

LoadingPage.defaultProps = {};

export default LoadingPage;
