import React from 'react';
import PropTypes from 'prop-types';
import './EventMega.css';

function EventMega(props) {
  var doc = props.data[0]
  console.log(props)
  try{
  return (
    <div className={props.main_page ? "EventMega_mianPage" : "EventMega"}>
      <img style={{ backgroundImage: 'url(' + props.data[0]["imgUrl"] + ')' }} />
      <div className='info'>
        <div className='eventTitle'>{props.data[0]["title"]}</div>
        <div className='eventDate'>{props.data[0]["date"]}</div>
        <div className='eventDiscription'>{props.data[0]["discription"]}</div>
      </div>
    </div>
  );}
  catch{
    return(<div></div>)
  }
}

EventMega.propTypes = {};

EventMega.defaultProps = {};

export default EventMega;
