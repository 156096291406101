import React from 'react';
import PropTypes from 'prop-types';
import './ContactUs.css';
import flagImg from '../../assests/ABJP_Flag.png'
import pr from '../../policies/privacy.pdf'
import refund from '../../policies/refund.pdf'
import terms from '../../policies/tc.pdf'
const ContactUs = () => (
  <div className="ContactUs">
    <div className='ContactOverlay'>
      <div className='ContactHolder'>
        <img src={flagImg} />
        <div className='InfoHolder'>
          Contact Us
          <p>Office No. 1, Kamini Housing Society,<br />
            Mahalaxmi Nagar, Near Gas Godown, <br />
            Ambernath (East), Dist-Thane, <br />
            421501, Maharashtra</p>
            <p>
            +91 932 480 0565
            <br/>info@abjp.org.in</p><br/>
            <div className='PolicyRow'>
              <a href={ pr} download>Privacy Policy</a>
              <a href={terms} download>Terms and Conditions</a>
              <a href={refund} download>Refund Policy</a>
            </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
);

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default ContactUs;
