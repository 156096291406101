import React from 'react';
import PropTypes from 'prop-types';
import './EventBox.css';

function EventBox(props) {
  console.table(props.doc)
  return (
    <div className={props.main_page?"EventBox_mainPage":"EventBox"}>
      <img style={{ backgroundImage: 'url('+props.doc["imgUrl"]+')' }} />
      <div className='info'>
        <div className='eventTitle'>{props.doc["title"]}</div>
        <div className='eventDate'>{props.doc["date"]}</div>
        <div className='eventDiscription'>{props.doc["discription"]}</div>
      </div>
    </div>
  );
}

EventBox.propTypes = {};

EventBox.defaultProps = {};

export default EventBox;
