import React from 'react';
import PropTypes from 'prop-types';
import './NavBar.css';
import { useState } from 'react';
import logo from '../../assests/logo.png'
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import getTranslation from '../../utils/translationHelper';

function NavBar(props) {
  const [menuTogle, setmenuTogle] = useState(false)
  function toggleMenu(){
    setmenuTogle(!menuTogle)
    if (menuTogle) {
      document.body.classList.remove('scroll_lock')
    }
    else{
      document.body.classList.add('scroll_lock')
    }
  }

  return (
    <div className="NavBar">
      <img src={logo} />
      <nav>
        <Link to='/' ><li className='active'>{getTranslation(props.language,"home")}</li></Link>
        <Link to='/about' ><li>{getTranslation(props.language,"about")}</li></Link>
        <Link to='/donate' ><li>{getTranslation(props.language,"donate")}</li></Link>
        <Link to='/members' ><li>{getTranslation(props.language,"members")}</li></Link>
        <Link to='/events' ><li>{getTranslation(props.language,"events")}</li></Link>
      </nav>
      <AnimatePresence>
        <motion.svg width="56" height="46" viewBox="0 0 56 46" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={toggleMenu}>
          <motion.rect width="56" height="46" rx="8" fill={!menuTogle?'#EAF6FF':'#2A2A72'}/>
          <line x1="15" y1="16" x2="40" y2="16" stroke={!menuTogle?'#232528':'#FFF'} stroke-opacity="0.68" stroke-width="2" stroke-linecap="round"/>
          <line x1="15" y1="24" x2="40" y2="24" stroke={!menuTogle?'#232528':'#FFF'} stroke-opacity="0.68" stroke-width="2" stroke-linecap="round"/>
          <line x1="15" y1="32" x2="40" y2="32" stroke={!menuTogle?'#232528':'#FFF'} stroke-opacity="0.68" stroke-width="2" stroke-linecap="round"/>
        </motion.svg>  
        {menuTogle?
        <motion.div className='navOverlay'
        exit={{opacity: 0}}
        ><motion.div className='mobileMenu'
        key={menuTogle}
        initial={{height: '0px',opacity: 0 }}
        animate={{height: 'auto', opacity: 1 }}
        exit={{height: '0px', opacity: 0 }}
        >
            <Link to='/' ><div className='mobileMenu-item'>{getTranslation(props.language,"home")}</div></Link>
            <Link to='/about' ></Link><div className='mobileMenu-item'>{getTranslation(props.language,"about")}</div>
            <Link to='/donate' ><div className='mobileMenu-item'>{getTranslation(props.language,"donate")}</div></Link>
            <Link to='/members' ></Link><div className='mobileMenu-item'>{getTranslation(props.language,"members")}</div>
            <Link to='/events' ></Link><div className='mobileMenu-item'>{getTranslation(props.language,"events")}</div>
        </motion.div></motion.div>:''}
        
      </AnimatePresence>
    </div>
  );
}

NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;
