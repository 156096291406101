import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import HeaderBar from './components/HeaderBar/HeaderBar';
import HomePage from './components/HomePage/HomePage';
import ContactUs from './components/ContactUs/ContactUs'
import MembersPage from './components/MembersPage/MembersPage'
import MemberFormPage from './components/MemberFormPage/MemberFormPage'
import DonationPage from './components/DonationPage/DonationPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import RecieptMeber from './components/RecieptMeber/RecieptMeber';
import RecieptDonation from './components/RecieptDonation/RecieptDonation';
import LoadingPage from './components/LoadingPage/LoadingPage';
import { useState } from 'react';
import EventPage from './components/EventPage/EventPage';
import AboutUs from './components/AboutUs/AboutUs';

function App() {
  const [language, setlanguage] = useState('English')
  return (
    <Router>
      <div className="App">
         <HeaderBar changeLang={setlanguage}/>
         <NavBar language = {language}/>
         <Switch>
           <Route exact path='/'>
             <HomePage language = {language} />
          </Route>
           <Route exact path='/members'>
            <MembersPage/>
          </Route>
          <Route exact path='/join'>
             <MemberFormPage />
           </Route>
           <Route exact path='/donate'>
             <DonationPage />
           </Route>
           <Route exact path='/events'>
             <EventPage />
           </Route>
           <Route exact path='/about'>
             <AboutUs language = {language} />
           </Route>
         </Switch>
         <ContactUs  />
       </div>
     </Router>
  );
}

export default App;
