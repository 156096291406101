import React from 'react';
import PropTypes from 'prop-types';
import './SelectXlable.css';

function SelectXlable(props) {
  return (
    <div className="SelectXlable">
      <label>{props.label}</label>
      <select onChange={e => props.onChange(e.target.value)}>
        {props.options.map((op)=>{return <option>{op}</option>})}
      </select>
    </div>
  );
}

SelectXlable.propTypes = {};

SelectXlable.defaultProps = {};

export default SelectXlable;
