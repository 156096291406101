import React from 'react';
import PropTypes from 'prop-types';
import './VisionContainer.css';

const VisionContainer = (props) => (
  <div className="VisionContainer" style={{backgroundImage: 'url('+props.bgImg+')'}}>
  <div className='VisionInfo' style={{backgroundColor: props.bgCol }}>{props.info}</div>
  <div className='VisionTitle' style={{backgroundColor: props.bgCol }}>{props.title} </div>
  </div>
);

VisionContainer.propTypes = {};

VisionContainer.defaultProps = {};

export default VisionContainer;
