import React from 'react';
import PropTypes from 'prop-types';
import './RecieptMeber.css';
import logo from '../../assests/logo.png'
import trns from '../../assests/ghadekar transparent.png'
import Pdf from "react-to-pdf";


function RecieptMeber(props) {
  const ref = React.createRef();
  return (
    <div className="RecieptMeber">
      <div className='Reciept' ref={ref}>
        <div className='RecieptHeader'>
          <h6>ABHINAV BHARAT JANASEVA PAKSH</h6><img src={logo} />
        </div>
        <div className='RecieptBody'>
          <div className='RecieptBodyBox'>
            <h2>Congradulations! <br /> You are a member of ABJP</h2>
            <div className='RecieptRow' style={{ display: 'flex', justifyContent:'center', alignItems:'center', marginTop:'110px' }}>
              <div className='RecieptMember'>
                <h3>Membership Details</h3>
                <table>
                  <tr>
                    <td>Name</td>
                    <td>{props.memObj.name}</td>
                  </tr>
                  <tr>
                    <td>Member ID</td>
                    <td>{props.memObj.memberID}</td>
                  </tr>
                  <tr>
                    <td>Payment Time</td>
                    <td>{props.memObj.time.toString().slice(0, 24)}</td>
                  </tr>
                </table>
              </div>
              <div className='ReciptDetails'>
                <h3>Payement Details</h3>
                <table>
                  <tr>
                    <th>Service</th>
                    <th>Cost</th>
                  </tr>
                  <hr />
                  <tr>
                    <td>Membership</td>
                    <td>₹100</td>
                  </tr>
                  <tr>
                    <td>ID Card</td>
                    <td>₹50</td>
                  </tr>
                  <tr>
                    <td>Online Charges</td>
                    <td>₹10</td>
                  </tr>
                  <hr />
                  <tr>
                    <th>Total</th>
                    <th>₹160</th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className='RecieptBodyBox'>
            <img src={trns} />
          </div>
        </div>
      </div>
      <Pdf targetRef={ref} filename="Invoice.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Download Invoice</button>}
      </Pdf>
    </div>
  );
}

RecieptMeber.propTypes = {};

RecieptMeber.defaultProps = {};

export default RecieptMeber;
