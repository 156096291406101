import React from 'react';
import PropTypes from 'prop-types';
import './PrimaryMemberCard.css';

function PrimaryMemberCard(props) {
  return (
    <div className="PrimaryMemberCard">
      <div className="PrimaryMemberCardOverlay"></div>
      <div className='CardDiv' style={{ backgroundImage: "url("+props.imgUrl+")" }}>
        <div className='PrimaryMemberName'>{props.name}</div>
        <div className='PrimaryMemberPost'>{props.post}</div>
      </div>
    </div>
  );
}

PrimaryMemberCard.propTypes = {};

PrimaryMemberCard.defaultProps = {};

export default PrimaryMemberCard;
