import React from 'react';
import PropTypes from 'prop-types';
import './RecieptDonation.css';
import logo from '../../assests/logo.png'
import Pdf from 'react-to-pdf';
import trns from '../../assests/ghadekar transparent.png'

function RecieptDonation(props) {
  const ref = React.createRef();
  return (
    <div className="RecieptDonation">
      <div className='Reciept' ref={ref}>
        <div className='RecieptHeader'>
          <h6>ABHINAV BHARAT JANASEVA PAKSH</h6><img src={logo} />
        </div>
        <div className='RecieptBody'>
          <div className='RecieptBodyBox'>
            <h2>Thank You For Your Donation</h2>
            <div className='RecieptRow' style={{ display: 'flex', justifyContent:'center', alignItems:'center' }}>
              <div className='ReciptDetails'>
                <h3>Donation Details</h3>
                <table style={{width:'500px'}}>
                  <hr />
                  <tr>
                    <td>Name</td>
                    <td>{props.memObj.name}</td>
                  </tr>
                  <tr>
                    <td>Donation ID</td>
                    <td>{props.memObj.donationId}</td>
                  </tr>
                  <tr>
                    <td>Donation Amount</td>
                    <td>{props.memObj.amount}</td>
                  </tr>
                  <tr>
                    <td>Donation Type</td>
                    <td>{props.memObj.donationType}</td>
                  </tr>
                  <tr>
                    <td>Donation Time</td>
                    <td>{props.memObj.time.toString().slice(0, 24)}</td>
                  </tr>
                  <hr />
                </table>
              </div>
            </div>
          </div>
          <div className='RecieptBodyBox'>
            <img src={trns} />
          </div>
        </div>
      </div>
      <Pdf targetRef={ref} filename="Invoice.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Download Invoice</button>}
      </Pdf>
    </div>
  );
}

RecieptDonation.propTypes = {};

RecieptDonation.defaultProps = {};

export default RecieptDonation;
