import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './EventHolder.css';
import EventMega from '../EventMega/EventMega';
import EventBox from '../EventBox/EventBox';
import { db } from '../../firebaseConfig'

function EventHolder(props) {

  const [eventLits, seteventLits] = useState([])
  const [eventsLoaded, seteventsLoaded] = useState(false)

  useEffect(() => {
    var evList = []
    db.collection('events').where('date',">=", new Date()).orderBy('date','asc').get().then((docs) => {
      docs.forEach((doc) => {
        var ev = { "date": doc.data()["date"].toDate().toString().slice(0, 16), "discription": doc.data()["discription"], "imgUrl": doc.data()["imgUrl"], "title": doc.data()["title"] }
        evList.push(ev)
      })
      console.log(evList)
    }).then(() => { seteventLits(evList); console.log(eventLits) ;seteventsLoaded(true)})

  }, [])

  return (
    <div className={props.main_page ? "EventHolder_mainPage" : "EventHolder"} >
      {eventsLoaded?<div className={props.main_page ? "EventHolder_mainPage" : "EventHolder"}>
      <EventMega main_page={props.main_page} data ={eventLits}/>
      <div className={props.main_page ? "BoxHolder_mainPage" : "BoxHolder"} main_page={props.main_page}>
        {
          eventLits.slice(1).map((doc) => { return <EventBox doc={doc} main_page={props.main_page} /> })
        }
      </div></div>:<div></div>
      }
    </div>
  );
}

EventHolder.propTypes = {};

EventHolder.defaultProps = {};

export default EventHolder;
