import React from 'react';
import PropTypes from 'prop-types';
import './InputXlable.css';

function InputXlable(props) {
  return (
    <div className="InputXlable">
      <label>{props.lable}</label>
      <input pattern={props.pattern} defaultValue={props.defaultValue} id={props.id} type={props.type} style={props.inputSytle} onChange={e =>{props.onChange(e.target.value)}} required/>
    </div>
  );
}

InputXlable.propTypes = {};

InputXlable.defaultProps = {};

export default InputXlable;
