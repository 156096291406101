import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import './PartyName.css';
import { motion,AnimatePresence  } from 'framer-motion';


function PartyName() {
  const [partyVar, setpatyvar] = useState(0)
  const [partyName, setpartyName] = useState('Abhinav Bharat Javaseva Paksh')
  const partyNameArr =['Abhinav Bharat Janaseva Paksh','अभिनव भारत जनसेवा पक्ष','অভিনব ভারত জনসেবা পক্ষ', 'அபினவ் பாரத் ஜனசேவா பக்ஷ்','अभिनव भारत जनसेवा पक्ष','અભિનવ ભારત જનસેવા પક્ષ']
  const [time, setTime] = useState(Date.now());


  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 3500);
    setpartyName(partyNameArr[partyVar])
    setpatyvar((partyVar+1)%partyNameArr.length)
    return () => {
      clearInterval(interval);
    };
  }, [time]);



  return (
    <div className="PartyName">
      <AnimatePresence>
      <motion.h1
        layout
        key={partyName}
        initial={{opacity: 0 }}
        animate={{y:0, opacity: 1 }}
        exit={{y:-100, opacity: 0 }}
        transition={{y:{duration: .2}, opacity:{duration: .2}}}
      >
        {partyName}
      </motion.h1>
      </AnimatePresence>
    </div>
  );
}

PartyName.propTypes = {};

PartyName.defaultProps = {};

export default PartyName;
