import React from 'react';
import PropTypes from 'prop-types';
import './AddAmountButton.css';

function AddAmountButton(props) {
  return (
    <div className="AddAmountButton">
      <div onClick={()=>props.onClick(props.amount)}>+{props.amount}</div>
    </div>
  );
}

AddAmountButton.propTypes = {};

AddAmountButton.defaultProps = {};

export default AddAmountButton;
