import React from 'react';
import PropTypes from 'prop-types';
import './LandingPageMemberCard.css';

const LandingPageMemberCard = (props) => (
  <div className="LandingPageMemberCard" style={{ backgroundImage: "url('" + props.bgImg + "')" }}>
    {/* <div className='MemberOverlay'></div> */}
    <div className='MemberName'>
      <p>{props.gender} {props.name}<br />
        {props.post}
      </p>
    </div>
  </div>
);

LandingPageMemberCard.propTypes = {};

LandingPageMemberCard.defaultProps = {};

export default LandingPageMemberCard;
