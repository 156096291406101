import React from 'react';
import PropTypes from 'prop-types';
import './AboutUs.css';
import getTranslation from '../../utils/translationHelper';

function AboutUs(props) {
  return (
    <div className="AboutUs">
      <div className='memberTitleDiv'>
        {/* <div className='memberOverlay'></div> */}
        <div className='TitleHolder'>
          <div className='MemberTitle'>About Us</div>
        </div>
      </div>
      <div className='Container'>
        <q>We are a political pary based in Maharashra, Our Goal is to work on various sectors like education, employment and empoverment</q>
      </div>
    </div>
  );
}

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
