import React from 'react';
import PropTypes from 'prop-types';
import './StatusBar.css';

function StatusBar(props) {
  var count = 0
  return (
    <div className="StatusBar">
      {props.items.map((item) => {
        const count1 = count; count++; return <div className='statusContainer' onClick={() => props.onclickEvn(count1)}>
          <div className={count > props.complitionStatus ? 'indexNumber' : 'indexNumber activeNumber'} >{count > props.complitionStatus ? count : <svg width="15" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 10.9999L11.0203 19L25 3" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" /></svg>}</div>
          <div className={count > props.complitionStatus ? 'statusTitle' : 'activestatusTitle'}>{item}</div>
        </div>
      })}
    </div>
  );
}

StatusBar.propTypes = {};

StatusBar.defaultProps = {};

export default StatusBar;
